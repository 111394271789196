<template>
    <div>
        <!-- Filter Section -->
        <b-card title="Filters" class="mb-0">
          <b-form @submit.prevent>
            <b-row>
                <!-- Period -->
                <b-col cols="12" md="2">
                    <b-form-group
                        :label="$t('apps.reportLedger.singular.period')"
                        label-for="period"
                    >
                        <v-select
                            class="select-size-sm"
                            v-model="form.period"
                            :options="LOV.period"
                            :reduce="field => field.value"
                            @input="handlePeriod"
                        />
                    </b-form-group>
                </b-col>
               <!-- Date Start -->
               <b-col cols="12" md="2">
                <b-form-group
                    :label="$t('apps.reportLedger.singular.dateStart')"
                    label-for="date-start"
                >
                  <flat-pickr
                      id="date-start"
                      :config="dateFormat"
                      v-model="form.dateStart"
                      class="form-control form-control-sm"
                  />
                </b-form-group>
               </b-col>
               <!-- Date End -->
               <b-col cols="12" md="2">
                <b-form-group
                    :label="$t('apps.reportLedger.singular.dateEnd')"
                    label-for="date-start"
                >
                    <flat-pickr
                        id="date-end"
                        :config="dateFormat"
                        v-model="form.dateEnd"
                        class="form-control form-control-sm"
                    />
                </b-form-group>
               </b-col>
               <!-- Agent -->
               <b-col cols="12" md="2">
                  <b-form-group
                    :label="$t('globalSingular.agent')"
                    label-for="office"
                  >
                    <v-select
                        class="select-size-sm"
                        v-model="form.agent"
                        :options="LOV.agent"
                        :reduce="field => field.id"
                        label="label"
                    />
                  </b-form-group>
               </b-col>
               <!-- Type Royalti  -->
               <b-col cols="12" md="2">
                <b-form-group
                    :label="$t('globalSingular.typeRolalti')"
                    label-for="office"
                  >
                    <v-select
                        class="select-size-sm"
                        v-model="form.typeRoyalti"
                        :options="LOV.typeRoyalti"
                        :reduce="field => field.value"
                        label="label"
                    />
                </b-form-group>
               </b-col>
                <!-- Button Filter -->
                <b-col cols="12" md="2">
                    <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
                        {{ $t('globalActions.find') }}
                    </b-button>
                </b-col>
            </b-row>
          </b-form>
        </b-card>
        <!-- bawah filters  -->
        <!-- button print  -->
        <div class="d-flex justify-content-end mt-1 mb-1">
          <b-button variant="outline-primary" @click="print">
            <feather-icon icon="FileIcon" />
            {{ $t('globalActions.print') }}
          </b-button>
          <b-button-group class="ml-1">
            <b-dropdown right variant="outline-primary">
                 <template #button-content>
                   <feather-icon icon="DownloadIcon"/>
                   {{ $t('globalActions.export') }}
                 </template>
                <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
                <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>
            </b-dropdown>
          </b-button-group>
        </div>
        <!-- Table Container Card -->
        <b-card no-body id="pdfDom">
            <b-card-body class="text-center">
              <b-card-title>{{ companyName }}</b-card-title>
              <b-card-sub-title>
                <div>{{ $t('apps.reportSummaryCH.singular.report') }}</div>
                <div class="mt-sm-1">{{ periodReportText }}</div>
              </b-card-sub-title>
            </b-card-body>

            <!-- table  -->
            <b-table
                id="refListTable"
                ref="refListTable"
                no-provider-paging
                no-provider-filtering
                :filter-included-fields="columnToBeFilter"
                :fields="tableColumns"
                :items="itemLists"
                :filter="searchQuery"
                responsive
                primary-key="id"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
            >
            <template #table-busy>
                <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
                  <div class="text mb-1">
                    <b-spinner class="align-middle"/>
                  </div>
                  <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
                </div>
            </template>

            <template #cell(agent)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '' ]">
                    {{ item.agent }}
                </span>
            </template>

            <template #cell(total_resi)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '' ]">
                    {{ Math.round(item.total_resi) }}
                </span>
            </template>

            <template #cell(total_nilai_royalti)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '' ]">
                    {{ formatCurrency(item.total_nilai_royalti) }}
                </span>
            </template>

            </b-table>

        </b-card>
    </div>
</template>
<script>
import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { format, getMonth, startOfMonth, endOfMonth, subMonths, addMonths, subYears, parse } from 'date-fns'
import useHttp from '@/comp-functions/useHttp'
import useListTable from '@/comp-functions/useListTable'
import { formatCurrency } from '@/utils/formatter'


export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    flatPickr,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BSpinner
  },
  computed: { 
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const periodReportText = ref()
    const { $get } = useHttp()
    const form = ref({
                    period: 'thisMonth',
                    dateStart: '',
                    dateEnd: '',
                    agent: '',
                    typeRoyalti: ''
                })
    const LOV = ref({
      period: [
        { value: 'thisMonth', label: 'This Month' },
        { value: 'previousMonth', label: 'Previous Month' },
        { value: 'thisYear', label: 'This Year' },
        { value: 'previousYear', label: 'Previous Year' }
      ],
      agent:[],
      typeRoyalti: [
        { value: 'CHI', label: 'ICT' },
        { value: 'CHB', label: 'BRAND' },
        { value: '', label: 'ALL' }
      ]
    })

    const columnToBeFilter = ['agent']


    const dateFormat = ref({ dateFormat: 'd/m/Y' })

    // Table Handlers
    const tableColumns = [
      { key: 'agent', label: 'Agent' },
      { key: 'total_resi', label: 'Total Resi' },
      { key: 'total_nilai_royalti', label: 'Total Nilai Royalti' }
    ]

    const handlePeriod = () => {
      const formatString = 'dd/MM/yyyy'
      const thisMonth = new Date()
      const whatMonth = getMonth(thisMonth) + 1
      const previousMonth = subMonths(thisMonth, 1)
      const firstMonthOfYear = subMonths(thisMonth, whatMonth - 1)
      const lastMonthOfYear = addMonths(thisMonth, (whatMonth - 12) * -1)
      const firstMonthOfPreviousYear = subYears(firstMonthOfYear, 1)
      const lastMonthOfPreviousYear = subYears(lastMonthOfYear, 1)

      if (form.value.period === 'thisMonth') {
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.period === 'previousMonth') {
        form.value.dateStart = format(startOfMonth(previousMonth), formatString)
        form.value.dateEnd = format(endOfMonth(previousMonth), formatString)
      } else if (form.value.period === 'thisYear') {
        form.value.dateStart = format(startOfMonth(firstMonthOfYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfYear), formatString)
      } else {
        form.value.dateStart = format(startOfMonth(firstMonthOfPreviousYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfPreviousYear), formatString)
      }
    }

    const getReport = async () => {
        const params = [
            `dateStart=${form.value.dateStart}`,
            `dateEnd=${form.value.dateEnd}`,
            `agent=${form.value.agent ?? ''}`,
            `typeRoyalti=${form.value.typeRoyalti ?? ''}`
          ]
      const url = `reports/summary/ch?${ params.join('&') }`
      await fetchLists(url)
    }

    const getAgent = async () => {
      const contactType = '{CTAG}'
      const { data } = await $get({ url: `master/contact?type=${contactType}`})
      LOV.value.agent = data
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      const text = `${formatStartDate} to ${formatEndDate}`
      periodReportText.value = text
      return text
    }

    onMounted(async () => {
      handlePeriod()
      updatePeriodText()
      getAgent()
      getReport()
    })

    return {
        form,
        LOV,
        handlePeriod,
        dateFormat,
        updatePeriodText,
        periodReportText,
        getReport,
        tableColumns,
        isBusy,
        itemLists,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        perPageOptions,
        searchQuery,
        isSortDirDesc,
        refListTable,
        statusFilter,
        fetchLists,
        columnToBeFilter,
        formatCurrency
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', '', '', '', ''],
            [`${this.$t('apps.reportPurchaseInvoice.singular.purchaseInvoiceReport')}`, '', '', '', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', '', '', '', ''],
            ['', '', '', '', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 9, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 9, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 9, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 9, r: 3 } }
          ],
          filename: `${this.$t('apps.reportPurchaseInvoice.singular.purchaseInvoiceReport')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
.dropdown-menu .dropdown-item {
    width: 100%;
  }

  /*#pdfDom td, #pdfDom th {*/
  /*  padding: 0.72rem;*/
  /*}*/

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }

</style>
